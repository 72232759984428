/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router-dom';

// Style
import './Login.css';
// import Loader from '../UI/Loader';
import Announcement from '../Home/Announcement/Announcement';
import LoadingSipnner from '../UI/LoadingSipnner';

function Login(props) {

    const [ showForgotPassword, setShowForgotPassword ] = useState(false);
    const [ showSubmitVerificationCode, setShowSubmitVerificationCode ] = useState(false);
    const [ email, setEmail ] = useState(undefined);
    const [ verificationCode, setVerificationCode ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordConfirmation, setNewPasswordConfirmation ] = useState('');
    const [ isSubmitting, setIsSubmitting] = useState(false)

    const navigate = useNavigate();

    // On load, check if user is already logged in and redirect to the homepage if so
    useEffect(() => {
        props.authStore.checkUser(navigate);
    },[navigate, props.authStore]);

    // Input field handlers
    const handleUsernameChange = e => props.authStore.setUsername(e.target.value);
    const handlePasswordChange = e => props.authStore.setPassword(e.target.value);
    const handleEmailChange = e => setEmail(e.target.value );
    const handleVerificationCodeChange = e => setVerificationCode(e.target.value);
    const handleNewPasswordChange = e => setNewPassword(e.target.value);
    const handleNewPasswordConfirmationChange = e => setNewPasswordConfirmation(e.target.value);
    const handleForgotPassword = (e) => setShowForgotPassword(true);

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        await props.authStore.login()
        .then(() => {
            const { token } = toJS(props.authStore)
            if (token) {
                navigate('/home');
            } else {
                navigate('/');
            }

        });
    }

    const handleSubmitVerification = (e) => {
        props.mainStore.setSuccess('Your request has been sent successfully!');
    }

    const handleSubmitForgot = (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        if (showSubmitVerificationCode){
            if(newPassword === '' || newPasswordConfirmation === '' || verificationCode === ''){
                props.mainStore.setError('All password fields are mandatory!');
                setIsSubmitting(false)
                return;
            }
            if (newPassword !== newPasswordConfirmation) {
                props.mainStore.setError('Passwords don\'t match!');
                setIsSubmitting(false)
                return;
            }
            let passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/;
            if (!passwordRegex.test(newPassword)){
                props.mainStore.setError('Password does not meet security requirements!');
                setIsSubmitting(false)
                return;
            }
            props.authStore.forgotPasswordSubmit(email, verificationCode, newPassword)
            .then(() => {
                if(props.authStore.verificationFailed) {
                    //Do nothing. Keep the password reset form intact
                } else {
                    setShowForgotPassword(false);
                    setShowSubmitVerificationCode(false);
                    setVerificationCode(undefined);
                    setNewPassword(undefined);
                }
                setIsSubmitting(false)
            })
            return;
        }
        props.authStore.forgotPassword(email)
        .then((data) => {
            if(!data?.status) {
                // do not enable password reset form if user is an active user
                if(data.error_description.includes('E003')) {
                    // enable password reset form if user is an active user
                    setShowForgotPassword(false);
                    setShowSubmitVerificationCode(true);
                }
            } else {
                // enable password reset form if user is an active user
                setShowForgotPassword(false);
                setShowSubmitVerificationCode(true);
            }
            setIsSubmitting(false)
        });
    }

    const handleForgotCancel = (e) => {
        setShowForgotPassword(false);
        setShowSubmitVerificationCode(false);
    }

    const handleCreateAccount = (e) => {
        window.location.href = 'https://aametals.com/contact';
    }

    const { underMaintenanceStartDate, underMaintenanceShowMessage, underMaintenanceHideMessage, progress, app_env } = props.mainStore;
    const { announcements } = props.authStore;

    //Get current date
    const current_date_time = new Date();

    //Compute the difference between the current time and the maintenance start and end dates
    const current_date_time_and_start_date_difference = (current_date_time.getTime() - new Date(underMaintenanceStartDate).getTime())

    // if(progress && progress.loading ){
    //     // return <Loader />
    // }
    return (
        <section className="login flex column nowrap center middle">
            {/* <div className="login__banner">
                Application for testing purposes only
            </div> */}

            {/* Announcements */}
            { announcements && <Announcement announcements={ announcements.shortMessages || [] } /> }
            { progress && progress.loading && <LoadingSipnner /> }
            { !progress?.loading && <div className="panel panel--display">
                {
                    //Show the maintenance notice during the maintenance period
                    announcements && announcements?.maintenanceMode[app_env] &&
                    <form className="login__form">
                        <a onClick={ handleCreateAccount }>
                            Sorry, we're down for scheduled maintenance right now. If you need assistance please &nbsp;<b>contact us</b>.
                        </a>
                    </form>
                }

                {/*show login form*/}
                { 
                    announcements && !announcements?.maintenanceMode[app_env] && !showForgotPassword && !showSubmitVerificationCode && (
                    <>
                        <img className="login__logo" src="./images/logo.png" alt="AA Metals" />
                        <form className="login__form" onSubmit={ handleSubmitForm }>
                            <input type="text" onChange={ handleUsernameChange } placeholder="Username" autoComplete="username" maxLength="50" required />
                            <input type="password" onChange={ handlePasswordChange } placeholder="Password" autoComplete="current-password" maxLength="50" required />
                            <button className="button button--block" name="button" type="submit">Login</button>
                            <a onClick={ handleForgotPassword }><b style={{ marginLeft: '3px', marginRight: '3px' }}>Forgot password?</b></a>
                            <br />
                            <a onClick={ handleCreateAccount }>If you don't have an account&nbsp;<b>contact us</b>.</a>
                            <br />
                            { (underMaintenanceShowMessage <= current_date_time) && (underMaintenanceHideMessage >= current_date_time) && <a>Your NetSuite account has an upcoming routine</a> }
                            { (underMaintenanceShowMessage <= current_date_time) && (underMaintenanceHideMessage >= current_date_time) && <a>maintenance activity on &nbsp;<b>{ underMaintenanceStartDate }</b></a>}
                        </form>
                    </>
                )}

                {/* Show the maintenance notice before the maintenance period */}
                { current_date_time_and_start_date_difference < 0 && <a>Your NetSuite account has an upcoming routine</a> }
                { current_date_time_and_start_date_difference < 0 && <a>maintenance activity on &nbsp;<b>{ new Date(underMaintenanceStartDate).toString().substring(0,21) }</b></a>}

                { /*Show the reset password form*/}
                { announcements && !announcements?.maintenanceMode[app_env] && showForgotPassword && (
                    <form className="login__form" onSubmit={ handleSubmitForgot }>
                        <input type="text" onChange={ handleEmailChange } placeholder="Please provide your email address" autoComplete="email" maxLength="50" required />
                        <button className="button button--block" name="button" type="submit" disabled={isSubmitting}>{isSubmitting ? 'submitting ...' : 'Confirm' }</button>
                        <button className="button button--neutral" name="button" type="button" onClick={ handleForgotCancel } style={{ width: '100%' }}>Cancel</button>
                        <a onClick={ handleCreateAccount }>If you don't have an account&nbsp;<b>contact us</b>.</a>
                    </form>
                )}

                { /*Show the password requirements instructions*/}
                { showSubmitVerificationCode && (
                    <form className="passwordReset__form login__form" onSubmit={ handleSubmitVerification } >
                        <div className="password-requirements">Password requirements:</div>
                        <ul className="password-tips">
                            <li>Minimum length 10 characters</li>
                            <li>1 uppercase character (A-Z)</li>
                            <li>1 lowercase character (a-z)</li>
                            <li>1 digit (0-9)</li>
                        </ul>
                        <div>
                            <label>Enter verification code</label>
                            <input type="text" onChange={ handleVerificationCodeChange } placeholder="Please provide the verification code" maxLength="30" required />
                        </div>
                        <div>
                            <label>Enter new password</label>
                            <input type="password" onChange={ handleNewPasswordChange } placeholder="New password" required />
                        </div>
                        <div>
                            <label>Re-type new password</label>
                            <input type="password" onChange={ handleNewPasswordConfirmationChange } placeholder="Confirm new password" required />
                        </div>
                        <button className="button button-block" name="button" type="button" disabled={isSubmitting} onClick={ handleSubmitForgot }>{isSubmitting ? 'submitting ...' : 'Submit' }</button>
                        <button className="button button--neutral" name="button" type="button" onClick={ handleForgotCancel } style={{ width: '100%' }}>Cancel</button>
                    </form>
                )}
            </div>}
        </section>
    );
}

export default inject('authStore', 'mainStore')(observer(Login));
